import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Typography from 'common/Typography';
import Banner, {
  BannerBackgroundColor,
  BannerContentColor,
  BannerVariant,
} from 'components/Banner';

const { SIMPLE_SECONDARY } = BannerVariant;
const { GREEN } = BannerBackgroundColor;
const { WHITE } = BannerContentColor;

const LdsPage: FC<LdsPageTypes.LdsProps> = ({
  data: {
    lds: { urls, seo, banner, body },
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner variant={SIMPLE_SECONDARY} backgroundColor={GREEN} contentColor={WHITE} {...banner} />
      <Container fluid>
        <Container gutter={false}>
          <Typography dangerouslySetInnerHTML={body} />
        </Container>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $path: [String]) {
    lds(lang: { eq: $lang }, url: { in: $path }) {
      ...LdsPageFragment
    }
  }
`;

export default LdsPage;
